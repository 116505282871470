.notification-badge {
  position: absolute;
  top: 5px;
  right: 15px;
  background-color: #a1a1a1;
  border: 1px solid gray;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.red {
  background-color: red;
}

.read-badge {
  position: absolute;
  background-color: blue;
  color: #fff;
  border: 1px solid gray;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.time-label {
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  color: gray;
  background-color: #f5f5f5;
  margin-left: 50px;
}

hr {
  width: 100%;
  border: none;
  margin: 0px !important;
  border-top: 1px solid #ccc;
}

.header-text {
  color: black;
  font-family: 'Inter-Medium', Helvetica;
  font-size: 14px;
  font-weight: 550;
  letter-spacing: 0;
}

.normal-text {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}

.hyperlink-text {
  cursor: pointer;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #2563eb;
}

.notification-list-element {
  max-height: 323px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  padding: 0px !important;
}
