@import '../../../colorVariables.scss';
@import '../../../mixins.scss';

.name-table-col1 {
  color: var(--Text-Primary, #000);

  /* Body/Large-regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.name-table-col1-link {
  color: #2563eb;

  /* Body/Large-regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  cursor: pointer;
}

.name-table-col1-no-data-disabled {
  color: var(--Text-Primary, #000);
  opacity: 0.5;
  /* Body/Large-regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.name-table-col1-no-data {
  color: #2563eb;
  opacity: 0.5;
  /* Body/Large-regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  cursor: not-allowed;
}
.org-table-container {
  overflow-x: auto;
  height: 710px !important;
  margin-top: 20px;
}
@media only screen and (max-width: 768px) {
  .org-table-container {
    overflow-x: auto;
  }
}

.org-ref-table {
  background-color: $white !important;
}
.org-ref-table th {
  padding: 0.625rem 1rem 0.625rem 34px !important;
  border-top: 0.063rem solid rgba(0, 0, 0, 0.1) !important;
}

.org-ref-table td {
  border-bottom: 0.063rem solid rgba(0, 0, 0, 0.1) !important;
  padding: 1rem $spacing-xl 18px $spacing-xl !important;

  label {
    margin-bottom: 0.571rem !important;
  }

  .reference-mainText.item,
  label.core-data-label {
    display: list-item;
  }

  .reference-mainText.item,
  .reference-mainText-muted.item {
    margin-bottom: 0.571rem;
    display: list-item;
  }
  padding-bottom: 0.642rem !important;
}

td.left.aligned.top.aligned.seven.wide {
  padding-left: $spacing-xl !important;
}

.view-more-btn {
  margin-left: -1rem;
  margin-top: 0.25rem;
}

.org-ref-table tr {
  padding: 0.063rem 0.063rem 0.063rem 0.063rem !important;
}

.org-ref-table thead {
  background-color: #fafafa;
}

.tbody-hover tbody tr:hover {
  background-color: #f3f4f6;
}

.org-ref-table-items {
  text-align: center !important;
}

.org-ref-table-team td,
.org-ref-table-team tr {
  padding: 0.75rem 40px !important;
  font-family: 'Inter', sans-serif;
  font-size: $Body-Default;
  color: #3b3b3b;
  font-weight: normal;
  font-style: normal;
}

.column-header-customers {
  color: var(--Text-Secondary, $subLabel);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.content-segment-custom {
  padding: 0rem 0rem 0rem 0rem !important;
}

.inactive-css {
  margin-left: 5px !important;
  .ui.label {
    background-color: $lightBackground !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    padding: 4px, 8px, 4px, 8px !important;
  }
}

.pending-status-css {
  margin-left: 5px !important;
  .ui.label {
    background-color: $Warm-light !important;
    font-weight: 400 !important;
    color: $Warm-dark !important;
    font-size: 12px !important;
    padding: 4px, 8px, 4px, 8px !important;
  }
}

.customers-container {
  display: flex;
  flex-direction: row;
}
