@import '../../colorVariables.scss';
.no-radius {
  border-radius: 0 !important;
}

.override-menu-height {
  .ui.inverted.segment,
  .ui.primary.inverted.segment {
    height: 60px !important;
  }
  .ui.secondary.inverted.menu {
    background-color: $black;
  }
}

.logo-image {
  .ui.tiny.image,
  .ui.tiny.images .image,
  .ui.tiny.images img,
  .ui.tiny.images svg {
    width: 122.826px !important;
    height: 25px !important;
  }
  .company-title-label {
    color: #fff;
    font-family: Mark-Bold;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.1px;
    margin-left: 3px;
  }
}

.menu-icons {
  width: 16px;
  height: 16px;
}
.menu-image {
  // margin-left: -10px;
  width: 30px;
  height: 30px;
}

.profile-pic {
  .ui.mini.image,
  .ui.mini.images .image,
  .ui.mini.images img,
  .ui.mini.images svg {
    width: 28px !important;
    height: 28px !important;
  }
}
.profile-pic-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  .ui.mini.image,
  .ui.mini.images .image,
  .ui.mini.images img,
  .ui.mini.images svg {
    width: 28px !important;
    height: 28px !important;
  }
}
