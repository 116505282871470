// sideBar.scss
@import '../../colorVariables.scss';
@import '../../mixins.scss';

.filter-button {
  padding: 4px 16px;
  gap: 4px;
  width: 127px;
  height: 28px;
  left: 1412px;
  top: 221px;
  background: #ef8123 !important;
  border-radius: 4px 4px 0px 0px;
  transform: rotate(-90deg);
  font-size: 10px !important;
  line-height: 20px !important;
  color: #fff !important;
}

.custom-sidebar {
  position: fixed;
  height: 100%;
  overflow-y: auto;
  background-color: black;
}

.filter-header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 22px;
  padding: 16px 24px 9px;

  .info-close {
    margin: 4px 0px 0px 8px;
    cursor: pointer;
  }

  .filter-text {
    color: var(--black, #000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .filter-save {
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    color: $gradientRight;
  }

  .filter-reset {
  }
  .filter-save {
    color: $gradientRight;
  }

  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  background-color: white;
  z-index: 1000;
}
.ui.visible.right.overlay.sidebar {
  background-color: #ffffff;
}

.filter-footer {
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 22px;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px 24px 9px;

  .footer-text {
    color: var(--black, #000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  border-top: 1px solid rgba(34, 36, 38, 0.15);
  background-color: white;
  z-index: 1000;
}

.filter-footer-mobile {
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 22px;
  bottom: 0;
  width: 100%;
  padding: 16px 24px 9px;
  background-color: white;
  z-index: 1000;
  margin-top: 40px;

  .footer-text {
    color: var(--black, #000);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  border-top: 1px solid rgba(34, 36, 38, 0.15);
  background-color: white;
  z-index: 1000;
}

.filter-close {
  margin-top: -4px;
}
.filter-style {
  background-color: #ffffff;
}

.ui.sidebar {
  position: fixed !important;
  height: 100% !important;
  overflow-y: auto !important;
}

.create-customer-button {
  background-color: black !important;
  color: white !important;
  border-radius: 0 !important;
}
.create-customer-page-wrapper {
  margin-right: -40px;
  margin-left: 20px;
  
  @media only screen and (max-width: 764px) {
    margin-right: 0px !important;
     margin-left: 0px !important;
  }

  .customer-content {
    width: 310px !important;
  }
}

.cancel-button {
  background-color: white !important;
  color: black !important;
  border-radius: 5px !important;
  border: 1px solid #e5e7eb !important;
}

.advance-filter {
  .filter-body {
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    margin-top: 0px;
    // margin-right: 24px;
    width: 285px;
    margin-bottom: 2px;
    margin-left: 5px;
    // margin-top: 0px, 24px, 2px, 24px;
    .filter-label {
      cursor: pointer;
      padding-top: 24px;
      padding-bottom: 18px;
      margin-left: 24px;
      font-size: $Body-Small;
      margin: 10px !important;
    }
    .filter-label-co {
      color: $subLabel !important;
      padding-bottom: 18px;
      margin: 0px !important;
    }
    .two-step-radio-container-admin {
      margin-left: 0px !important;
      .ui.checkbox label:before {
        width: 28px !important;
        height: 16px !important;
      }
      .ui.toggle.checkbox label {
        padding-left: 40px;
      }
      .ui.toggle.checkbox label:after {
        width: 13.09px;
        height: 13.09px;
      }
      .ui.toggle.checkbox input:checked ~ label:after {
        left: 14px;
        top: 1px;
      }
      .ui.toggle.checkbox input ~ label:after {
        left: 1px;
        top: 1px;
      }
    }
    .ui.checkbox label {
      margin-bottom: 0px !important;
      padding-left: 30px;
      padding-bottom: 20px;
    }
    .ui.divider.divider-margin {
      margin: 6px -6px 0px -10px;
    }
    .filter-components-column {
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      display: flex;
      flex-direction: column;
      .show-all-button {
        cursor: pointer;
        color: $Primary;
        margin-bottom: 18px;
      }
    }
    .filter-components-row {
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      display: flex;
      flex-direction: row;

      .grid-column {
        margin-right: 26px;
      }
    }
    .show-all-button {
      cursor: pointer;
      color: $Primary;
      margin-bottom: 18px;
    }
  }

  .dropdown-advanced-filter {
    margin-top: 15px;
  }

  .orange-reset {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px !important;
    gap: 8px;

    height: 20px;

    background: $white;

    border: 1px solid $Gray-70;
    border-radius: 4px;

    img.resetImg {
      width: 12px !important;
      height: 12px !important;
    }
  }

  .filter-reset-flex {
    margin-right: 10px;
    align-items: flex-end;

    .blue-label {
      line-height: 20px !important;
      justify-content: flex-end;

      display: flex;
      margin-left: auto;
      flex-direction: row;
      justify-content: center; // Center horizontally
      align-items: center; // Center vertically
      padding: 0px 0px 0px 0px !important; // Remove left padding
      margin-left: 0 !important; // Remove left margin
      gap: 10px;

      min-width: 30px;
      height: 30px;

      background: $Primary-lighter;
      border-radius: 50%; // Make the label circular

      flex: flex-end;
      order: 0;
      margin-right: 10px;
      flex-grow: 0;
    }
    // margin-right: -150px;
    // margin-top: -10px;
    // justify-content: flex-end;
    //margin-bottom: 20px;
    // margin-left: 120px;
  }
  .accordion-title {
    margin-bottom: 15px;
    justify-content: center;
    align-items: center; // Center vertically
  }
  .accordion-content {
    margin-left: 10px;
  }

  .btn-flex {
    margin-top: 8px;
    height: 18px;
    .ui.horizontal.label {
      // margin-right: -10px;
    }
  }
  .btn-flex-act {
    @include display-flex(space-between);
  }
}
.ui.visible.right.overlay.sidebar {
  background-color: #ffffff;
  width: 350px;
}


.remove-mobile-margin{
  @media only screen and (max-width: 767px) {
    .ui.container {
      margin-left:110px !important;
    }
  }
}