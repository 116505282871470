@import '../../colorVariables.scss';
@import '../../mixins.scss';
.main-layout {
  min-height: 100vh;
  overflow: hidden;
}
.child-components-css {
  background-color: $orgBackground !important;
  padding: 30px 30px !important;
}

.loadingContainer {
  display: flex;
  min-height: 100vh !important;
  height: 100% !important;
  width: 100% !important;
  background-color: $gradientRight;
}
.main-content-header {
  display: flex;
  height: 5rem !important;
  min-height: 5rem !important;
  max-height: 5rem !important;
  border: 0.063rem solid rgba(223, 223, 223, 0.5);
  box-shadow: 0 0.125rem 0.375rem 0 rgba(223, 223, 223, 0.5);
  background-color: $white;

  .header-grid {
    width: 100%;

    .header-title {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      height: 100%;

      .header-title-container {
        display: flex;
        align-items: center;

        .header-title-text {
          max-width: 47.5rem;
          color: $info;
          font-family: $fontFamily;
          font-size: $h6;
          font-weight: bold;
          line-height: 2.357rem;
          margin-left: 3.071rem;
          margin-bottom: 0rem;
        }
      }

      .header-title-group {
        @include Body-Default;

        .header-title-group-color {
          color: $gradientRight;
        }
      }
    }

    .profile-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;

      .text {
        color: $info;
        font-family: $fontFamily;
        font-size: $Body-Large;
        line-height: 1.5rem;
      }
    }
  }
}
.background-layout {
  background-color: $orgBackground !important;
  padding: 10px 30px 0px 30px !important;
}

.background-layout-sidebar {
  background-color: $orgBackground !important;
  height: fit-content !important;
  //height: 650px !important;
}
