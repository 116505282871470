@import '../../../colorVariables.scss';

.card-icon {
  display: flex;
  flex-direction: row;
}

.override-semantic-card {
  .ui.card,
  .ui.cards > .card {
    width: auto !important;
    height: 144px !important;
    // @media (max-width: 768px) {
    //   width: 320px !important;
    // }
    // @media (max-width: 768px) {
    //   width: 320px !important;
    // }
  }
  .ui.card > .content > .header + .description,
  .ui.card > .content > .meta + .description,
  .ui.cards > .card > .content > .header + .description,
  .ui.cards > .card > .content > .meta + .description {
    margin-top: 30px !important;
  }
}

.description-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.column-date {
  display: flex;
  flex-direction: column;
}

.column-based-text {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  .card-header-text {
    color: $black;
    font-family: 'Inter-Medium', Helvetica;
    font-size: 14px;
    letter-spacing: 0;
  }
  .card-header-sub-text {
    color: $subLabel;
    font-family: 'Inter-Regular', Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 12px;
  }
  .card-header-sub-text-inverted {
    color: $white !important;
    font-family: 'Inter-Regular', Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 12px;
  }
}

.card-header-sub-text {
  color: $subLabel;
  font-family: 'Inter-Regular', Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 12px;
}

.card-header-text {
  color: $black;
  font-family: 'Inter-Medium', Helvetica;
  font-size: 14px;
  letter-spacing: 0;
}

.date-time {
  color: $black;
  font-family: 'Inter-Medium', Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
}

.average-value {
  color: $green;
  font-family: 'Inter-Regular', Helvetica;
  font-size: 12px;
  font-weight: 400;
}

.row-card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 5px;

  .ui.dropdown > .text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #767676;
  }

  i.dropdown.icon {
    color: #767676;
  }
}

.add-new-button {
  cursor: pointer;
  margin-top: 15px;
}
.title-card {
  font-size: 14px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.title-card-inverted {
  font-size: 14px;
  margin-top: 5px;
  color: $white;
}

.main-title {
  color: $black;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}
.dashboard-parent-component-css {
  padding: 20px 30px !important;

  @media only screen and (max-width: 768px) {
    padding: 0px 0px !important;
  }

  .dashboard-application-list-css {
    .ui.stackable.grid > .row > .column {
      padding: 0px !important;
    }
    padding: 0px !important;
    @media (max-width: 768px) {
      padding-top: -30px;
      .ui.stackable.grid > .row > .column {
          padding: 0px  !important;
        }
    }
  }
  .dashboard-application-view-css {
    padding-right: 0px !important;
  }
}

.recent-application-list-css {
  padding: 0px !important;
}

.custom-row-app-home-one-view{
  padding:20px !important;

}
.custom-row-app-home {
  margin-top: 25px;
}

@media only screen and (max-width: 767px) {
  /* Styles for screens smaller than 768px (e.g., mobile devices) */
  .ui.grid {
    display: block !important;
  }

  .ui.grid > .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ui.grid > .row > .column {
    width: 100% !important;
  }

  .dashboard-element-controller {
    display: flex;
    flex-direction: column;
  }
}
