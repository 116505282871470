@import '../../colorVariables.scss';
@import '../../mixins.scss';
.application-address-back {
  margin: 0.4rem 0;
  .application-address-back-arrow {
    border-style: none !important;
  }
}

.back-text-color {
  margin-left: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $Primary !important;
  cursor: pointer;
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.custom-check-box {
  display: flex;
  margin-top: 5px;
  justify-content: flex-end;
  padding-left: 9px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;

  .terms {
    cursor: pointer;
  }

  .ui.checkbox input:checked ~ label:before,
  .ui.checkbox label:before {
    background: #f5f5f5;
  }
}

.custom-check-box-update {
  display: flex;
  margin-top: 45px;
  justify-content: flex-end;
  padding-left: 9px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;

  .terms {
    cursor: pointer;
  }

  .ui.checkbox input:checked ~ label:before,
  .ui.checkbox label:before {
    background: #f5f5f5;
  }
}

.custom-text-area-comment{
  width: auto;
  width: 92%;
  .formik-custom-field {
    label,
    .label {
      @include Body-Small($Text-Secondary);
      display: inline-block;
    }

    .required-stars:after {
      content: ' *';
      color: red;
      font-size: 14px !important;
    }

    .invalid-feedback {
      display: block;
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545;
    }

    .info-text {
      font-family: 'Inter' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 150% !important;
      color: #8c8c8c !important;
    }
  }
  .txt-description {
    width: -webkit-fill-available;
    height: 120px;
    padding: 16px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    @media (max-width: 768px) {
      // width: 300px !important;
      // margin-right: -190px !important;
    }
  }
}

.custom-check-box-old {
  display: flex;
  margin-top: 5px;
  padding-left: 9px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  margin-bottom: 10px;
  .terms {
    cursor: pointer;
  }

  .ui.checkbox input:checked ~ label:before,
  .ui.checkbox label:before {
    background: #f5f5f5;
  }
}


