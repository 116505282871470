@import '../../colorVariables.scss';
@import '../../mixins.scss';

.auth {
  background-color: $white !important;

  @media only screen and (max-width: 1000px) {
    margin-right: 0.625rem;
  }

  margin: 0 !important;

  min-height: 100vh;

  .whiteboxLoginLogo {
    @media only screen and (min-width: 500px) {
      padding-left: 161px;
      padding-top: 2.25rem;
      cursor: pointer;
    }

    @media only screen and (max-width: 500px) {
      padding-top: 2.25rem;
      padding-left: 1.875rem;
      cursor: pointer;
    }
  }

  .txt-description {
    width: 400px;
    @media (max-width: 768px) {
      // width: 300px !important;
      // margin-right: -190px !important;
    }
  }
  .main-title-settings {
    color: #000;
    font-family: Inter;
    margin-bottom: 100px;
    margin-top: 20px;
    margin-left: 40px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    padding-bottom: 50px;
  }
  .sett-margin-top {
    margin-top: 100px;
  }

  .main-title-settings-tabs {
    color: #000;
    font-family: Inter;
    margin-top: 20px;
    margin-left: 40px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    padding-bottom: -50px;
    background-color: #ffffff;
    line-height: 26px;
    padding: 10px;
  }
  .title-row {
    height: -500px;
  }
  .ui.secondary.menu .active.item:hover {
    background-color: black;
    background: black;
    color: white;
  }
  .ui.secondary.menu .active.item {
    background-color: black;
    background: black;
    color: white;
  }
  .header-context-lbl {
    padding-left: 15px;
    padding-top: 20px;
  }
  .ui.button {
    display: inline-flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #ffffff;
    background-color: black;
  }
  .ui.button:hover {
    color: white;
    background-color: black;
  }
  .ud-div {
    padding-left: 30px;
    padding-top: 10px;
  }
  .ud-lbl::before {
    content: '• ';
    color: black;
  }
  .custom-text-area {
    width: auto;
   
  }

  .ud-lbl {
    color: var(--black, #000);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
  .button-area {
    margin-top: 30px;
  }
  .green-lbl {
    color: var(--green-600, #059669);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
  .active-tab {
    color: black !important;
  }
  .title-row-settings {
    background-color: $orgBackground;
  }
  .button-row-set {
    margin-right: -600px;
  }
  .acc-stt-header {
    color: #3b3b3b;

    /* Body - Large - Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.1px;
    align-items: center;
  }
  .auth-column {
    background-color: #fafafa;
    height: auto;
  }


  .settings-accounts {
    margin: 10px 10px 10px 10px;
  }
  .account-row-reset-pass {
    margin-top: -40px;
    margin-bottom: 20px;
  }
  .account-row {
    margin-top: -40px;
  }

  .whiteboxFullPageLogo {
    margin-top: 2.25rem;
    margin-left: 147px;
    cursor: pointer;
  }
  .forgotpass-inputs {
    height: 40px;
  }

  .taksoLoginLogo {
    @media only screen and (max-width: 500px) {
      margin-left: 1.875rem;
      cursor: pointer;
    }

    margin-top: 2.25rem;
    margin-left: 161px;
    cursor: pointer;
  }

  .header {
    height: 5.063rem;
    position: sticky !important;
    width: 100%;
    background-color: $white;
    z-index: 999;
    top: 0;
    box-shadow: 0rem 0.125rem 0.125rem 0rem rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0rem 0.125rem 0.125rem 0rem rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0rem 0.125rem 0.125rem 0rem rgba(0, 0, 0, 0.1);

    @media only screen and (max-width: 767px) {
      .custom-column {
        .whitebox {
          padding-left: 0rem !important;
          display: flex;
          justify-content: center;
        }

        .header-route {
          padding-right: 0rem !important;
          display: flex !important;
          justify-content: center !important;
        }
      }
    }

    .custom-column {
      height: inherit;

      .whitebox {
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 5rem;

        .headerText {
          color: $gradientRight;
          font-family: $AuthPageFont;
          font-size: $h6;
          font-weight: bold;
          line-height: 33px;
          text-align: center;
          margin-bottom: 0;

          a {
            color: $gradientRight;
          }
        }
      }

      .header-route {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 5rem;

        .infoText {
          color: $infoText;
          font-family: $AuthPageFontLight;
          font-size: $Body-Large;
          line-height: 1.75rem;
        }

        .infoRoute {
          color: $gradientRight;
          font-family: $AuthPageFontLight;
          font-size: $Body-Large;
          line-height: 1.75rem;

          a {
            color: $gradientRight;
          }
        }
      }
    }
  }

  .footer-row {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    display: flex;
    justify-content: center;
  }

  @media only screen and (max-width: 767px) {
    .header {
      height: auto;
      min-height: 5.063rem;
    }

    .footer {
      height: auto;
      min-height: 92px;
    }

    .footer-row {
      padding-top: 0.625rem;

      .footer-img {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .signInFormCol {
    display: flex;

    .signin-page-wrapper {
      @media only screen and (max-width: 500px) {
        display: block;
      }
    }
  }

  .fixedSideDetails {
    .letIcon {
      @media only screen and (min-width: 400px) and (max-width: 1000px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 5rem;
        height: 9.375rem;
      }

      @media only screen and (max-width: 400px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 70px;
        height: 11.25rem;
      }

      @media only screen and (min-width: 1000px) and (max-width: 141.25rem) {
        position: absolute;
        top: 0;
        left: 0;
        width: 5rem;
        height: 9.375rem;
      }

      position: absolute;
      top: 0;
      left: 0;
      width: 104px;
      height: 20.625rem;
    }

    .rightIcon {
      @media only screen and (max-width: 1000px) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 4.688rem;
        height: 21.25rem;
      }

      @media only screen and (max-width: 400px) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 65px;
        height: 185px;
      }

      @media only screen and (min-width: 1000px) and (max-width: 141.25rem) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 4.688rem;
        height: 21.25rem;
      }

      position: absolute;
      bottom: 0;
      right: 0;
      width: 106px;
      height: 314px;
    }

    .sign-in {
      .container {
        height: 100%;
        margin-top: 160px;
        padding-right: 9.375rem;
        padding-left: 9.375rem;

        @media screen and (max-width: 1750px) {
          padding-right: 3.75rem;
          padding-left: 3.75rem;
        }

        @media screen and (max-width: 1385px) {
          padding-right: 1.875rem;
          padding-left: 1.875rem;
        }

        @media screen and (max-width: 1261px) {
          padding-right: 0rem;
          padding-left: 0rem;
        }

        // @media only screen and (min-width: 500px) and(max-width: 1460px) {
        //   margin-top: 9.375rem;
        //   margin-bottom: 6.25rem;
        // }

        @media only screen and (max-width: 500px) {
          margin-top: 180px;
        }

        .leftSideContent {
          text-align: center;
          width: 100%;
          height: 108px;
          line-height: 2.25rem;
          margin-bottom: 1.875rem !important;
          font-weight: 500;
          font-family: $AuthPageFont;
          font-size: $h6;

          .orangeText {
            margin-left: 0.313rem !important;
            color: #fd7057;
          }
        }

        .middleContent {
          @media only screen and (max-width: 500px) {
            margin-bottom: 230px;
          }

          text-align: left;
          font-size: $Body-Small;
          font-weight: 400;
          width: 100%;
          font-family: $AuthPageFont;
          margin-bottom: 0.938rem;

          .list {
            margin-bottom: 1.25rem;
          }

          .profileImg {
            margin-top: 5.5rem;
            bottom: 0;
          }

          .quoteText {
            width: 316px;
            height: 6rem;
            text-align: center;
            font-family: $fontFamily;
            font-size: $Body-Default;
            line-height: 1.5rem;
          }

          .quoteName {
            margin-top: 1rem;
            font-family: $fontFamily;
            font-weight: 600;
            font-size: $Body-Default;
            line-height: 1.5rem;
          }

          .bold-text {
            margin-left: 0.313rem !important;
            font-weight: bold !important;
          }
        }

        .bottom-content {
          font-style: italic !important;
        }
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      margin-top: 21.25rem;

      // @media only screen and (min-width: 500px) and(max-width: 1460px) {
      //   margin-top: 9.375rem;
      // }

      @media only screen and (max-width: 500px) {
        margin-top: 180px;
      }

      .takso-definition {
        font-size: $Body-Large;
        margin-bottom: 1.125rem;
      }

      .leftSideContent {
        text-align: center;
        width: 357px;
        height: 108px;
        line-height: 2.25rem;
        margin-bottom: 65px;
        font-weight: 500;
        font-family: $AuthPageFont;
        font-size: $h6;

        .orangeText {
          color: #fd7057;
        }
      }

      .middleContent {
        @media only screen and (max-width: 500px) {
          margin-bottom: 230px;
        }

        text-align: center;
        font-size: $Body-Small;
        font-weight: 400;
        font-family: $AuthPageFontLight;
        margin-bottom: 0.938rem;

        .list {
          margin-bottom: 1.25rem;
        }

        .profileImg {
          margin-top: 5.5rem;
          bottom: 0;
        }

        .quoteText {
          width: 316px;
          height: 6rem;
          text-align: center;
          font-family: $fontFamily;
          font-size: $Body-Default;
          line-height: 1.5rem;
        }

        .quoteName {
          margin-top: 1rem;
          font-family: $fontFamily;
          font-weight: 600;
          font-size: $Body-Default;
          line-height: 1.5rem;
        }
      }
    }
  }

  .right-heading-container {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    padding-left: 161px;

    @media only screen and (max-width: 500px) {
      padding-left: 1.25rem;
    }

    .headerNotification {
      width: 100%;
      color: #757575;
      height: 72px;
      font-family: $AuthPageFontLight;
      font-size: $Body-Default;
      text-align: left;
      padding-right: 1.875rem;
      margin-bottom: $spacing-xl;
    }

    .forgot {
      color: $info;
      font-family: $AuthPageFont;
      font-size: $Body-Small;
      line-height: 1.5rem;
      margin-bottom: 2.857rem;
      text-decoration: underline;
    }

    .form-group-conatiner {
      @media only screen and (max-width: 500px) {
        padding-right: 0 !important;
      }

      padding-right: 1.875rem;
    }

    .alreadyContent {
      width: 100%;
      height: 1.25rem;
      font-family: $AuthPageFont;
      font-weight: 400;
      font-size: $Body-Small;
      color: #000000;

      .signin-label {
        color: #000000;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    .headerText {
      width: 100%;
      color: $info;
      margin-top: 6.25rem;
      font-family: $AuthPageFont;
      font-size: $h5;
      font-weight: 600;
      line-height: 2.563rem;
      text-align: left;

      a {
        color: $white;
      }
    }

    .headerDescription {
      width: 100%;
      opacity: 0.8;
      color: $info;
      font-family: $AuthPageFont;
      font-size: $Body-Default;
      line-height: 1.5rem;
      margin-bottom: $spacing-xl;
      text-align: left;
      font-weight: lighter;
      padding-right: 1.875rem;
    }

    .errorBox {
      background-color: #ffebee;
      border-radius: 0.25rem;

      .errorBoxContent {
        margin: 0.75rem 1rem 0.75rem 1rem;
        width: 418px;
        @include Body-Small($Error);
        text-align: left;
      }
    }

    .successBox {
      background-color: #82f59e;
      border-radius: 0.25rem;

      .successBoxContent {
        margin: 0.75rem 1rem 0.75rem 1rem;
        width: 418px;
        @include Body-Small($Success);
        text-align: left;
      }
    }

    .signin-inputs {
      font-family: $AuthPageFont;
      height: 3.125rem;
      display: flex;
    }

    .signin-button {
      width: 100% !important;
      border-radius: 0.25rem;
      background-color: #000000;
      font-family: $AuthPageFont;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
      color: white;
      margin: $spacing-lg 0;
    }

    .btn-primary.signin-button {
      background-color: #000000 !important;
      border-color: #000000 !important;
      color: white;
    }

    .goBackSignIn {
      width: 100%;
      display: flex;
      justify-content: center;
      cursor: pointer;
      color: #000000;
      font-size: $Body-Small;
      line-height: 1.25rem;
      font-family: $AuthPageFont;
      margin-top: $spacing-xl;
      text-decoration-line: underline;
    }

    .subscriptionText {
      width: 100%;
      font-size: $Body-Small;
      font-family: $fontFamilyLight;
      font-weight: 500;
    }

    .sub-inputs {
      width: 100%;
    }

    .sub-detail-container-verification {
      background-color: #fffaf9;
      padding: 1rem;
      border-radius: 0.313rem;
      margin-bottom: $spacing-lg;
      width: 100%;

      .plan-include-text {
        font-family: $AuthPageFont;
        font-weight: 500;
        font-size: $Body-Default;
        line-height: 1.5rem;
        margin-bottom: 1rem;
      }

      .detail {
        margin-top: 0.313rem !important;
        display: flex;
        flex-direction: column;
        margin-left: 1.75rem !important;

        label {
          font-family: $AuthPageFontLight !important;
          font-size: $Body-Small !important;
          font-weight: 400 !important;
          line-height: 1.5rem !important;
          margin-right: 0.75rem;
        }

        .orangeCorrectIcon {
          color: #fd7057;
        }

        .planList {
          font-family: $AuthPageFontLight;
          font-weight: 400;
          font-size: $Body-Small;
          line-height: 1.5rem;
        }
      }
    }

    .form-group > label {
      color: $info;
      font-family: $AuthPageFont;
      font-size: $Body-Small;
      line-height: 1.125rem;
      font-weight: 500;
      width: 100%;
    }

    .accountTypeHeader {
      width: 100%;
      color: $info;
      font-family: $AuthPageFont;
      font-size: $Body-Small;
      line-height: 1.125rem;
      margin-bottom: 0.5rem;
      text-align: left;
      font-weight: 500;
      color: $info;
    }

    .heading-row {
      @media only screen and (min-width: 141.25rem) {
        margin-left: 147px;
      }

      .form-group > label {
        color: $info;
        font-family: $AuthPageFont;
        font-size: $Body-Small;
        line-height: 1.125rem;
        font-weight: bold;
        width: 100%;
      }

      .EmailHeaderText {
        color: $info;
        margin-top: 6.25rem;
        font-family: $AuthPageFont;
        font-size: $h5;
        font-weight: 600;
        line-height: 2.563rem;
        text-align: center;
      }
    }

    .heading-row-verification {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .greenIcon {
        width: 32;
        height: 32;
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        position: relative;
      }

      .verification-button {
        height: 3.125rem;
        width: 450px;
        border-radius: 0.25rem;
        background-color: #fd7057;
        font-family: $AuthPageFont;
        font-size: $Body-Default;
        font-weight: 500;
        line-height: 1.375rem;
        align-items: center !important;
        color: white;
      }

      @media only screen and (max-width: 767px) {
        .verification-button {
          height: 3.125rem;
          width: 225px;
          border-radius: 0.25rem;
          background-color: #fd7057;
          font-family: $AuthPageFont;
          font-size: $Body-Large;
          font-weight: 600;
          line-height: 1.5rem;
          align-items: center !important;
          color: white;
        }
      }

      @media only screen and (max-width: 48rem) {
        .verification-button {
          height: auto;
          width: auto;
          border-radius: 0.25rem;
          background-color: #fd7057;
          font-family: $AuthPageFont;
          font-size: $Body-Large;
          font-weight: 600;
          line-height: 1.5rem;
          align-items: center !important;
          color: white;
        }
      }

      .headerText-verification {
        font-family: $AuthPageFont;
        font-style: normal;
        font-weight: 500;
        font-size: $h5;
        line-height: 2.813rem;
        align-items: center;
        text-align: center;
        letter-spacing: -0.019px;

        color: #3b3b3b;

        a {
          color: $gradientRight;
        }
      }

      .headerText-verification-sub {
        font-family: $AuthPageFont;
        font-style: normal;
        font-weight: 500;
        font-size: $h5;
        line-height: 2.813rem;
        align-items: center;
        text-align: center;
        letter-spacing: -0.019px;

        color: #3b3b3b;

        a {
          color: $gradientRight;
        }
      }

      .button-align {
        text-align: center;
        margin-bottom: 1.875rem;
      }
    }
  }

  .footer {
    border-top: 0.125rem solid rgba(0, 0, 0, 0.1);
    background-color: $white;
    width: 100% !important;
    height: 3.125rem;

    @media only screen and (max-width: 767px) {
      .custom-column {
        .mail-to {
          padding-left: 0rem !important;
          display: flex;
          justify-content: center;
        }

        .sub-routes {
          padding-right: 0rem !important;
          display: flex !important;
          justify-content: center !important;
        }
      }
    }

    .custom-column {
      height: inherit;

      .mail-to {
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 5rem;

        .mail-text {
          color: $gradientRight;
          font-family: $AuthPageFont;
          font-size: $Body-Default;
          font-weight: bold;
          line-height: 1.5rem;
          text-align: center;

          a {
            color: $gradientRight;
          }
        }
      }
    }

    .sub-routes {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 5rem;

      .route-separate {
        margin-left: 1rem;
        margin-right: 1rem;
        font-family: $AuthPageFontLight;
        font-size: $Body-Large;
      }

      .route {
        color: $gradientRight;
        font-family: $AuthPageFontLight;
        font-size: $Body-Default;
        line-height: 1.375rem;

        a {
          color: $gradientRight;
        }
      }
    }
  }
}

@media only screen and (min-width: 18.75rem) {
  .ui.container {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-right: 0rem;
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
  padding-right: 0rem !important;
}

.help-tips {
  display: flex;
  flex-direction: row;
  margin: 0rem 0rem $spacing-lg 0rem;

  .ui.toggle.checkbox {
    label {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Small;
      line-height: 1.25rem;
      font-style: normal;
      font-weight: 400;
    }
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }
}

.guest-user-register {
  .signin-inputs {
    height: 3.125rem;
    width: 100%;
  }
}

.headerName {
  font-family: sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: $Body-Small;
  line-height: 19.07px;
}

.cardStyle {
  margin-bottom: $spacing-lg;
  display: flex;
  flex-direction: row;
  padding-right: 1.875rem;
  justify-content: space-between;
}

.Icon {
  padding-right: 3.125rem;
}

.icon-alignment {
  display: flex;
  flex-direction: row;
}

.firstName {
  padding-right: 1.563rem;
}

.email-verification-container {
  min-height: 18.75rem;
}

.verify-button {
  background-color: $gradientRight !important;
  font-family: $AuthPageFont !important;
  color: white !important;
}

.MainText {
  font-family: $AuthPageFont;
  font-style: normal;
  font-weight: 600;
  font-size: $Body-Small;
  text-align: left;
  margin-bottom: 0;
  margin-left: 1rem;
  color: #3b3b3b;
}

.MainText:hover {
  color: #ef8123;
}

.SubText-one {
  font-family: $AuthPageFont;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  text-align: left;
  margin-left: 1rem;
  margin-right: $spacing-sm;
  padding-bottom: 0.5rem;
  color: #979797;
}

.SubText-two {
  font-family: $AuthPageFont;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  margin-left: 1rem;
  margin-right: $spacing-sm;
  margin-bottom: 1.25rem;
  text-align: left;
  color: #979797;
}

.MainText-clicked {
  font-family: $AuthPageFont;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  margin-left: 1rem;
  margin-bottom: 0;
  text-align: left;
  color: #ef8123;
}

.button-style-custom {
  display: inline-block;
  padding: 0.313rem;
}

.button-style-custom-icon {
  display: inline-block;
  padding: 0.313rem;
}

.rectangle-box {
  width: 21.5rem;
  height: 68px;
  background: #ffffff;
  border: 0.063rem solid #ef8123;
  box-sizing: border-box;
  box-shadow: 0rem 0.25rem 0.857142857rem 0.063rem rgba(0, 0, 0, 0.04);
  border-radius: 0.375rem;
}

.phone-input-style {
  width: 100% !important;
  height: 3.125rem !important;
}

.phone-input-style-tel-error {
  height: 3.125rem !important;
  border-color: red !important;

  .react-tel-input .flag-dropdown {
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    padding: 0 !important;
    background-color: #701818 !important;
    border: 0.063rem solid #cacaca !important;
    border-radius: 0.188rem 0 0 0.188rem !important;
  }
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  margin-left: 0rem;
  bottom: 0;
  padding-right: 0.688rem !important;
  background-color: #f5f5f500 !important;
  border: 0.063rem solid #cacaca;
  border-radius: 0.188rem 0 0 0.188rem;
  border-color: rgba(255, 255, 255, 0) #cacaca rgba(255, 255, 255, 0.014)
    rgba(255, 255, 255, 0) !important;
}

.card-style-custom-one {
  width: 100%;
  background: #ffffff;
  border: 0.063rem solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0rem 0.25rem $spacing-sm 0.063rem rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem;
  margin-right: 1.25rem;
}

.card-style-custom-one:hover {
  border: 0.063rem solid #ef8223;
  cursor: pointer;
}

.card-style-custom-one-clicked {
  width: 100%;
  background: #ffffff;
  border: 0.063rem solid #ef8223;
  box-sizing: border-box;
  box-shadow: 0rem 0.25rem $spacing-sm 0.063rem rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem;
  margin-right: 1.25rem;
  cursor: pointer;
}

.card-style-custom-two:hover {
  border: 0.063rem solid #ef8223;
  cursor: pointer;
}

.card-style-custom-one:hover .icon-color-style-one {
  color: #ef8123;
}

.card-style-custom-one:hover .SubText-one {
  color: #ef8123;
}

.card-style-custom-two:hover .MainText {
  color: #ef8123;
}

.icon-color-style-one-clicked {
  color: #ef8123;
}

.icon-color-style-two-clicked {
  color: #ef8123;
}

.card-style-custom-two {
  width: 100%;
  background: #ffffff;
  border: 0.063rem solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0rem 0.25rem $spacing-sm 0.063rem rgba(0, 0, 0, 0.04);
  border-radius: 0.375rem;
  margin-left: 0.125rem;
}

.card-style-custom-two-clicked {
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0rem 0.25rem $spacing-sm 0.063rem rgba(0, 0, 0, 0.04);
  border-radius: 0.375rem;
  margin-left: 0.125rem;
  border: 0.063rem solid #ef8223;
  cursor: pointer;
}

.icon-style-custom {
  flex: 1;
  margin-top: 0.5rem;
  margin-left: 1rem;
  width: 1.25rem;
  height: 1.25rem;
}

.para-style-custom {
  flex: 5;
  padding-top: 0.75rem;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  margin-left: 0rem;
  bottom: 0;
  padding-right: 0.688rem !important;
  background-color: #f5f5f500 !important;
  border: 0.063rem solid #cacaca;
  border-radius: 0.188rem 0 0 0.188rem;
}

.react-tel-input .form-control {
  position: relative;
  font-size: $Body-Small;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 58px !important;
  margin-left: 0;
  background: #ffffff;
  border: 0.063rem solid #cacaca;
  border-radius: 0.313rem;
  line-height: 1.563rem;
  height: 2.188rem;
  width: 18.75rem;
  outline: none;
}

.password-align {
  padding-top: 0rem;
  margin-top: -1.125rem;
  padding-bottom: 0.938rem;
}

@media only screen and (max-width: 767px) {
  .text-muted-password {
    text-align: left !important;
  }

  .nameFields {
    width: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .para-style-custom {
    flex: 5;
    padding-top: 0.75rem;
    padding-bottom: 0.438rem;
  }

  .password-align {
    padding-top: 0rem;
    margin-top: -1.125rem;
    padding-bottom: 0.938rem;
  }
}

.your-plan-text {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $Body-Default;
  line-height: 1.375rem;
  text-transform: uppercase;
  color: #3b3b3b;
}

.dropdown-text {
  padding-top: 0.313rem;
  padding-bottom: 1.25rem;
}

.subscription-text {
  padding-top: 0.188rem;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1rem;
  color: #3b3b3b;
}

.plan-details {
  width: 100%;
  height: 134px;
  background: #f8f8f8;
  border-radius: 0.25rem;
  margin-bottom: 0.938rem;
}

.plan-details-column {
  display: flex;
  flex-direction: column;
}

.plan-details-column-one {
  flex: 1;
  width: fit-content;
}

.plan-include-text {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1rem;
  text-transform: uppercase;
  color: #3b3b3b;
}

.plan-details-column-two {
  flex: 5;
  width: fit-content;
}

.plan-detail-inside {
  display: flex;
  flex-direction: row;
}

.plan-detail-inside-one {
  margin-right: 0.313rem;
  margin-left: 1.25rem;
}

.plan-detail-inside-custom-two {
  display: flex;
  flex-direction: row;
  padding-top: 1.25rem;
}

.plan-detail-inside-two {
  margin-left: 85px;
}

.plan-detail-inside-two-changed {
  margin-left: 135px;
}

.plan-detail-inside-main {
  display: flex;
  flex-direction: column;
  padding-top: 0.938rem;
}

.back-button-style {
  float: left;
  padding-top: 3.75rem;
  padding-left: 0.313rem;
  cursor: pointer;
}

.back-text-color {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $Body-Default;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  color: #979797;
  display: inline-block;
}

.step-button-style {
  float: right;
  padding-top: 3.75rem;
  padding-right: 0.313rem;
  display: none;
}

.step-button-style-mobile {
  float: right;
  padding-top: 3.75rem;
  padding-right: 0.313rem;
}

.back-text-color-custom {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $Body-Small;
  line-height: 1.375rem;
  align-items: center;
  color: #979797;
  display: inline-block;
}

.back-text-color {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $Body-Default;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  color: #979797;
  display: inline-block;
}

.dropdown-style {
  height: 3rem !important;
  font-size: $Body-Default !important;
}

.back-text-color-custom-responsive {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $Body-Small;
  line-height: 1.375rem;
  align-items: center;
  color: #979797;
  display: inline-block;
}

.back-text-color-responsive {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $Body-Default;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  color: #979797;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .header {
    height: auto;
    min-height: 5.063rem;
  }

  .footer {
    height: auto;
    min-height: 92px;
  }

  .footer-row {
    padding-top: 0.625rem;

    .footer-img {
      display: flex;
      flex-direction: column;
    }
  }

  .step-button-style-mobile {
    float: right;
    padding-top: 3.75rem;
    padding-right: 0.313rem;
    display: none;
  }

  .step-button-style {
    float: right;
    padding-top: 3.75rem;
    padding-right: 0.313rem;
    display: initial;
  }
}

.text-muted-password {
  color: #6c757d !important;
}

.parent-width {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .plan-detail-inside {
    display: flex;
    flex-direction: column;
  }

  .button-align {
    text-align: center;
  }

  .plan-detail-inside-one {
    margin-right: 0rem;
    margin-left: 0.313rem;
  }

  .plan-detail-inside-two {
    margin-left: 0.313rem;
  }

  .plan-detail-inside-two-changed {
    margin-left: 0.313rem;
  }

  .plan-detail-inside-custom-two {
    display: flex;
    flex-direction: column;
    padding-top: 0.125rem;
  }

  .plan-details {
    width: 100%;
    height: 164px;
    background: #f8f8f8;
    border-radius: 0.25rem;
  }
}

.headerDescription-verification {
  margin-top: $spacing-lg;
  font-family: $AuthPageFont;
  font-style: normal;
  font-weight: 400;
  font-size: $Body-Default;
  line-height: 1.5rem;
  text-align: center;
  color: #3b3b3b;
  margin-bottom: 3rem;
}

.verification-gap {
  margin-top: 0.313rem;
  margin-bottom: 0.938rem;
}

.verification-gap-button-top {
  margin-top: 2.188rem;
}

.headerText-subscription {
  text-align: center;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: $h5;
  line-height: 2.563rem;
  padding-top: 3.75rem;
  color: #3b3b3b !important;

  a {
    color: $white;
  }
}

.headerDescription-subscription {
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Large;
  line-height: 1.75rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #979797;
}

.current-plan {
  background: #ffffff;
  border: 0.063rem solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 0.25rem;
  height: 3rem;
  width: 100%;
  margin-bottom: 1rem;
}

.current-plan-text {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.188rem;
  /* identical to box height */
  color: #3b3b3b;
  padding-left: 1rem;
  padding-top: 0.938rem;
  padding-bottom: $spacing-sm;
}

.your-plan-subscription {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $Body-Default;
  line-height: 1.375rem;
  text-transform: uppercase;
  color: #3b3b3b;
  margin-top: 3.75rem;
}

.subtext {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1rem;
  color: #3b3b3b;
  margin-bottom: 0.313rem;
  margin-top: 1rem;
}

.payment-text {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.313rem;
  color: #3b3b3b;
}

.ui.grid > .row {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: inherit;
  align-items: stretch;
  width: 100% !important;
  padding: 0;
  //padding-top: 0 !important;
  // padding-bottom: 1rem;
}

.ui.grid > .row-report-approval {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: inherit;
  align-items: stretch;
  width: 100% !important;
  padding: 1rem 0 !important;
}

// @media only screen and (max-width: 767px) {
//   .ui.grid > .stackable.stackable.row > .column,
//   .ui.stackable.grid > .column.grid > .column,
//   .ui.stackable.grid > .column.row > .column,
//   .ui.stackable.grid > .column:not(.row),
//   .ui.stackable.grid > .row > .column,
//   .ui.stackable.grid > .row > .wide.column,
//   .ui.stackable.grid > .wide.column {
//     width: 100% !important;
//     margin: 0 0 !important;
//     box-shadow: none !important;
//     padding: 0 !important;
//   }
// }

.required-input {
  width: 450px !important;
  margin-top: 0.25rem !important;
  font-size: 80% !important;
  color: #dc3545 !important;
}

.info-table th,
.info-table td {
  padding: 0.625rem 0.938rem !important;
  border: transparent;
}

.recoverySub {
  font-family: $AuthPageFont;
  font-size: $Body-Default;
  line-height: 1.5rem;
  text-align: left;
  width: 16.875rem;
  font-weight: 400;
  margin-top: 1rem;
}

.recoveryEmail {
  font-family: $AuthPageFont;
  font-size: $Body-Default;
  line-height: 1.5rem;
  text-align: left;
  width: 16.875rem;
  font-weight: 600;
}

.backToSignIn {
  color: #fd7057;
  font-size: $Body-Small;
  line-height: 1.25rem;
  font-family: $AuthPageFont;
  width: 126px;
  margin-top: $spacing-lg;
  text-decoration-line: underline;
  cursor: pointer;
}

.footerContainer {
  margin-top: 192px;

  .footerContent {
    width: 16.875rem;
    height: 42px;
    font-size: $Body-Small;
    line-height: 21px;
    font-family: $AuthPageFont;
    color: #757575;
    font-style: normal;
    font-weight: 400;
  }

  .contactUs {
    color: #fd7057;
    font-size: $Body-Small;
    line-height: 1.25rem;
    font-family: $AuthPageFont;
    width: 126px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.profile-details {
  @include segment-container;

  .formik-custom-field {
    margin-top: 0rem !important;
    margin-bottom: $spacing-lg !important;
  }
}

.help-tips {
  @include segment-container;

  .row {
    margin-bottom: 0rem !important;
  }
}

.frequently-asked-questions {
  .row {
    padding: $spacing-lg $spacing-xl !important;
  }
}

.change-password {
  .formik-custom-field .ui.input {
    width: 64% !important;
  }

  .ui.label.label {
    width: 160px;
  }

  @include segment-container;

  .formik-custom-field {
    margin-top: 0rem !important;
    margin-bottom: 1.5rem !important;
  }

  input[type='password'] {
    border-radius: 0rem 0.5rem 0.5rem 0rem !important;
  }
}

.formik-custom-field .ui.input input {
  border-radius: 0.5rem !important;
  line-height: 50px;
}
.acc-update-button {
  display: inline-flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #000;
}
.sett-margin-top {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  padding-bottom: 20px;
  padding-top: 10px;
}
.custom-tab-pane {
  border: none !important;
  background-color: $orgBackground !important;
}


.button-down{
  margin-top: 10px;
}