@import '../../colorVariables.scss';
@import '../../mixins.scss';

.close-button {
  background-color: $black !important;
  color: $white !important;
}

.delete-button {
  background-color: #c80101 !important;
  color: $white !important;
}

.confirm-vehicle-import-application {
  .update-application {
    box-shadow: none !important;

    .ui.grid.update-application-form {
      .ui.selection.dropdown.custom-drop-down {
        min-width: 230px !important;
      }
    }
  }

  .date-picker {
    .css-4jnixx-MuiStack-root {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      min-height: 50px !important;
    }
    .css-4jnixx-MuiStack-root > .MuiTextField-root {
      min-width: 230px !important;
      min-height: 40px;
    }
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
      max-height: 40px !important;
    }
  }
}
