$black: #000000;
$white: #ffffff;
$info: #3b3b3b;
$infoText: #767676;
$background: #fafafa;
$orgBackground: #FAF9F9;
$gradientLeft: #e40166;
$gradientRight: #ef8123;
$gradientDelete: #c62828;
$amber: #ff781d;
$green: #059669;
$red: #d6001a;
$lightRed: #ec031f;
$success: #447e01;
$parentBackground: #faf6f3;
$parentTab: #ffe6d1;
$selected: #fbfbfb;
$editGroup: #006572;
$complete: #2185d0;
$subLabel: #757575;
$linkLabel: #1e40af;
$lightBackground: #f5f5f5;
$fontFamilyLight: 'Inter-Light', sans-serif;
$fontFamily: 'Inter', sans-serif;
$AuthPageFont: 'Poppins', sans-serif;
$AuthPageFontLight: 'Poppins-Light', sans-serif;

//New Color Theme

//Brand Colors
$Primary :#2563EB;
$Primary-dark: #F3F6FF;
$Primary-light: #D3E0FB;
$Primary-lighter: #D3E0FB;


//Alerts
$Success-light: #e8f5e9;
$Success: #05944f;
$Success-dark: #205823;
$Warm-light: #fff7da;
$Warm: #ffc043;
$Warm-dark:#BD640D;
$Warm-light: #fff7da;
$Error-light: #ffebee;
$Error: #e11900;
$Error-dark: #c62828;
$Info-light: #eff6ff;
$Info: #1e40af;
$Info-dark: #00215e;

//Neutral
$White: #ffffff;
$Gray-10: #fafafa;
$Gray-20: #f5f5f5;
$Gray-30: #eeeeee;
$Gray-40: #e0e0e0;
$Gray-50: #bdbdbd;
$Gray-60: #9e9e9e;
$Gray-70: #757575;
$Gray-80: #616161;
$Gray-90: #424242;
$Gray-100: #212121;
$Black: #000000;

//Text & Usage
$Text-Primary: #000000;
$Text-Secondary: #757575;
$Background: #fafafa;
$Stroke: #e0e0e0;
$Disable: #bdbdbd;



//Report
$cultural: #ffb500;
$economic: #7b60a8;
$social: #ffa28b;
$environment: #007868;
$governance: #194b00;

//8px
$spacing-xs: 0.571428571rem;
//12px
$spacing-sm: 0.857142857rem;
//16px
$spacing-md: 1.142857143rem;
//24px
$spacing-lg: 1.714285714rem;
//32px
$spacing-xl: 2.285714286rem;
//48px
$spacing-2xl: 3.428571429rem;
//64px
$spacing-3xl: 4.571428571rem;

//64px
$h1: 4.571428571rem;
//48px
$h2: 3.428571429rem;
//36px
$h3: 2.571428571rem;
//30px
$h4: 2.142857143rem;
//24px
$h5: 1.714285714rem;
//20px
$h6: 1.428571429rem;
//16px
$Body-Large: 1.142857143rem;
//14px
$Body-Default: 1rem;
//12px
$Body-Small: 0.857142857rem;
